import React from 'react'
import PropTypes from 'prop-types'

const TopElement = ({ text, title }) => {

  return (
    <div className="relative mx-auto max-w-screen-md py-24">
        {title && (
          <h2 className="h2 text-center text-4xl md:text-5xl text-black mb-5 pt-10">{title}</h2>
        )}
        <div className="border-b-3 mx-auto border-secondary w-32 h-18"></div>
        {text && (
          <p className="pt-6 text-center">{text}</p>
        )}
    </div>
  )
}

TopElement.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string
}


export default TopElement