import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import '../assets/css/grid-element.css'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const RefElement = ({ projektName, className, refNr, caption }) => {

  const options = {
    caption: {
      captionFontFamily: "Lato",
      captionFontWeight: "300",
    }
  }

  const data = useStaticQuery(graphql`{
  ref1: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz1"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref2: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz2"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref3: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz3"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref4: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz4"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref5: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz5"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref6: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz6"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref7: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz7"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref8: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz8"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  ref9: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "referenz9"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  refi1: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "immo1"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  refi2: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "immo2"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  refi3: allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: "immo3"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        title: name
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
` )

var images
refNr === '1' && (images = data.ref1)
refNr === '2' && (images = data.ref2)
refNr === '3' && (images = data.ref3)
refNr === '4' && (images = data.ref4)
refNr === '5' && (images = data.ref5)
refNr === '6' && (images = data.ref6)
refNr === '7' && (images = data.ref7)
refNr === '8' && (images = data.refi1)
refNr === '9' && (images = data.refi2)
refNr === '10' && (images = data.refi3)
refNr === '11' && (images = data.ref8)
refNr === '12' && (images = data.ref9)


const firstItem=images.edges[0].node // fetches the first item
const image = getImage(firstItem.childImageSharp.gatsbyImageData)

  return (
    <div className={"ref cursor-pointer relative block " + className}>

      <div className='absolute z-20 w-full h-full overflow-hidden lightBox'>
        <SimpleReactLightbox>
          <SRLWrapper options={options}>

        {images.edges.map( ({ node }) => {
          const image = getImage(node.childImageSharp.gatsbyImageData)
          return <GatsbyImage key={node.id} alt={caption} image={image} className="opacity-0 h-520" />;
        })}

          </SRLWrapper>
        </SimpleReactLightbox>
      </div>

    <div className="absolute z-10 flex flex-wrap content-end w-full h-full p-8 pb-10 lg:p-12 lg:pb-16 lg:opacity-0 overlayRef hover:opacity-100 lg:duration-500">

      <h3 className="w-full text-3xl text-white h2 md:text-4xl lg:text-5xl">{projektName}
      <div className="w-24 border-b-3 border-secondary"/></h3>

    </div>
    <GatsbyImage loading="eager" image={image} className="h-full bg-gray-100" alt="" />
  </div>
  );
}

export default RefElement